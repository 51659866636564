<template>
    <div style="background: #f7f7f7">
        <sample-header header_title="条形码打印" />
        <div class="content">
            <div class="number-box">
                <a-card title="编号信息" :bordered="false" :bodyStyle="cardStyle">
                    <div class="record-detail-info">
                        <div class="record-detail-info-title">编号形式：</div>
                        <div class="record-detail-info-val">2020050001</div>
                    </div>
                </a-card>
            </div>
            <div class="tag-box">
                <a-card title="标签信息" :bordered="false" :bodyStyle="cardStyle">
                    <a-list item-layout="horizontal" :data-source="tagInfo">
                        <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta>
                            <div class="record-detail-info" slot="description">
                                <div class="record-detail-info-title">{{ item.type }}：</div>
                                <div class="record-detail-info-val">{{ item.val }}</div>
                            </div>
                        </a-list-item-meta>
                        </a-list-item>
                    </a-list>
                </a-card>
            </div>
            <div class="info-box">
                <a-card title="打印说明" :bordered="false" :bodyStyle="cardStyle">
                    <a-list item-layout="horizontal" :data-source="infoData">
                        <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta :description="index + 1 + '. ' + item">
                            
                        </a-list-item-meta>
                        </a-list-item>
                    </a-list>
                </a-card>
            </div>
            <div class="print-box">
                <div class="print-btn">立即打印</div>
            </div>
        </div>
    </div>
    
</template>


<script>
import Header from '@/components/header.vue';

export default {
    name: "Sample",
    components: {
        'sample-header': Header,
    },
    data() {
        return {
            cardStyle: {
                padding: "10px 24px"
            },
            tagInfo: [
                {
                    'type': '标签数量',
                    'val': 50
                },
                {
                    'type': '所属区域',
                    'val': '南关镇卫生院'
                }
            ],
            infoData: [
                "保持手持打印标签机处于开机状态",
                "保持手持打印标签机处于开机状态",
                "保持手持打印标签机处于开机状态"
            ]
        }
    },
}
</script>

<style scoped>
.content {
    min-height: calc(100vh - 50px);
    width: 90%;
    margin: 0 auto;
}

.number-box {
    margin: 20px 0;
    width: 100%;
}

.tag-box {
    width: 100%;
    margin: 20px 0;
}

.info-box {
    width: 100%;
    margin: 20px 0;
}

.print-box {
    width: 100%;
    margin: 20px 0;
}

.record-detail-info {
    display: flex;
}

.record-detail-info-title {
    width: 50%;
}

.record-detail-info-val {
    width: 50%;
    text-align: right;
}


.print-btn {
    width: 90%;
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
    background: #aaaaaa;
    margin: 0 auto;
    border-radius: 10px;
}
</style>