var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sample-header',{attrs:{"header_title":"登录"}}),_c('div',{staticClass:"avatar-box"},[_c('a-avatar',{attrs:{"size":100,"src":"/image/logo.jpg"}})],1),_c('div',{staticClass:"login-title"},[_vm._v("采样小助手")]),_c('div',{staticClass:"form-box"},[_c('a-form',{ref:"form",attrs:{"form":_vm.form,"wrapper-col":{ span: 24 }},on:{"submit":_vm.onSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'phone',
                        { rules: _vm.rules.phone, trigger: 'blur' }
                    ]),expression:"[\n                        'phone',\n                        { rules: rules.phone, trigger: 'blur' }\n                    ]"}],staticClass:"custom-input",attrs:{"placeholder":"请输入手机号码"}})],1),_c('a-form-item',{ref:"password",attrs:{"rules":[{ required: true, message: 'Please input your password!' }]}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        { rules: _vm.rules.password, trigger: 'blur' }
                    ]),expression:"[\n                        'password',\n                        { rules: rules.password, trigger: 'blur' }\n                    ]"}],staticClass:"custom-input",attrs:{"type":"password","placeholder":"请输入登录密码"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-btn",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }