<template>
    <div class="header">
        <div class="header-title">{{ header_title }}</div>
        <a-icon @click="goBack" class="arrow" type="left" />
    </div>
</template>


<script>
export default {
    name: "Header",
    props: {
        header_title: {
            type: String
        }
    },
    data() {
        return {
            showArrow: true
        }
    },
    methods: {
        goBack() {
            window.history.back();
        }
    }
}
</script>

<style scoped>
    .header {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        font-weight: 800;
        background: #ffffff;
        border-bottom: 1px solid white;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
        position: relative;
    }

    .header-title {
        text-align: center;
    }

    .arrow {
        position: absolute;
        top: 18px;
        left: 10px;
    }
</style>