<template>
    <div class="menu">
        <div class="menu-item">
            <router-link to="/sample">
                <a-icon class="icon" type="file" />
                <div class="menu-title">采样</div>
            </router-link>
        </div>
        <div class="menu-item">
            <router-link to="/user">
                <a-icon class="icon" type="user" />
                <div class="menu-title">我的</div>
            </router-link>
        </div>
    </div>
</template>


<script>
export default {
    name: "Menu",
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>
    .menu {
        display: flex;
        justify-content: space-around;
        align-content: center;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #f4f4f4;
        padding: 10px 0;
        z-index: 9999;
        background: #ffffff;
    }
    
    .menu-title {
        color: #7f7f7f
    }
    .icon {
        font-size: 20px;
        color: #7f7f7f;
    }
</style>