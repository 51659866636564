<template>
    <div>
        <sample-header header_title="采样" />
        <div class="content">
            <div class="bar-box"><div class="bar"></div></div>
            <div class="sample-box">
                <router-link to="/sample/camera"><div class="sample-btn">开始采样</div></router-link>
            </div>
        </div>
        <sample-menu />
    </div>
    
</template>


<script>
import Header from '@/components/header.vue';
import Menu from '@/components/menu.vue';

export default {
    name: "Sample",
    components: {
        'sample-header': Header,
        'sample-menu': Menu
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>
.content {
    height: calc(100vh - 115.5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.bar-box {
    height: 30%;
}

.sample-box {
    height: 50%;
}

.bar {
    width: 90%;
    height: 100px;
    background: #aaaaaa;
    border-radius: 5px;
    margin: 10px auto;
}

.sample-btn {
    width: 180px;
    height: 180px;
    background: #448ef7;
    border-radius: 180px;
    line-height: 180px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

</style>