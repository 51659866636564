<template>
    <div>
        <sample-header header_title="个人中心" />
        <div class="user-info-box">
            <div class="user-info-avatar">
                <a-avatar class="avatar-box" :size="50"/>
            </div>
            <div class="user-info-detail">
                <div class="detail-name">{{ userInfo.name }}</div>
                <div class="detail-phone">{{  userInfo.phone }}</div>
            </div>
        </div>
        <div class="record-box">
            <a-tabs default-active-key="1" @change="changeTab">
                <a-tab-pane key="today">
                    <div slot="tab">
                        <div class="record-num">{{ todaySample.length }}</div>
                        <div class="record-title">今日采样</div>
                    </div>
                    <a-spin :spinning="isSpinning" tip="数据加载中...">
                        <div class="list-box">
                            <a-list item-layout="horizontal" :data-source="todaySample">
                                <a-list-item slot="renderItem" slot-scope="item">
                                <a-list-item-meta>
                                    <div slot="title">采样号: {{ item['UID'] }}</div>
                                    <div class="record-detail-info" slot="description">
                                        <div class="record-detail-info-name">姓名: {{ item.patientName }}</div>
                                        <div class="record-detail-info-time">{{ item.AddTime }}</div>
                                    </div>
                                </a-list-item-meta>
                                </a-list-item>
                            </a-list>
                        </div>
                    </a-spin>
                    <!-- <div class="records">
                        <div class="record-detail-num">
                            采样号: 2000000220000
                        </div>
                        <div class="division"></div>
                        <div class="record-detail-info">
                            <div class="record-detail-info-name">姓名: 王小二</div>
                            <div class="record-detail-info-time">2023/05/27 13:00</div>
                        </div>
                    </div> -->
                </a-tab-pane>
                <a-tab-pane key="history">
                    <div slot="tab">
                        <div class="record-num">{{ historyNumber }}</div>
                        <div class="record-title">历史采样</div>
                    </div>
                    <a-spin :spinning="isSpinning" tip="数据加载中...">
                        <div class="list-box">
                            <a-list item-layout="horizontal" :data-source="historySample">
                                <a-list-item slot="renderItem" slot-scope="item">
                                <a-list-item-meta>
                                    <div slot="title">采样号: {{ item['UID'] }}</div>
                                    <div class="record-detail-info" slot="description">
                                        <div class="record-detail-info-name">姓名: {{ item.patientName }}</div>
                                        <div class="record-detail-info-time">{{ item.AddTime }}</div>
                                    </div>
                                </a-list-item-meta>
                                </a-list-item>
                            </a-list>
                        </div>
                    </a-spin>
                </a-tab-pane>
            </a-tabs>
        </div>
        <sample-menu />
    </div>
    
</template>


<script>
import Header from '@/components/header.vue';
import Menu from '@/components/menu.vue';
import request from '../utils/request.js';

export default {
    name: "User",
    components: {
        'sample-header': Header,
        'sample-menu': Menu
    },
    data() {
        return {
            userInfo: {
                name: null,
                phone: null,
            },
            isSpinning: true,
            todaySample: [],
            historySample: [],
            historyNumber: 0
            // recordData: [
            //     {
            //         title: '2000000220000',
            //         name: '王小二',
            //         time: '2023/05/27 13:00',
            //     },
            //     {
            //         title: '2000000220001',
            //         name: '王小三',
            //         time: '2023/05/27 13:00'
            //     },
            //     {
            //         title: '2000000220002',
            //         name: '王小四',
            //         time: '2023/05/27 13:00'
            //     },
            //     {
            //         title: '2000000220003',
            //         name: '王小五',
            //         time: '2023/05/27 13:00'
            //     },
            //     {
            //         title: '2000000220003',
            //         name: '王小五',
            //         time: '2023/05/27 13:00'
            //     },
            //     {
            //         title: '2000000220003',
            //         name: '王小五',
            //         time: '2023/05/27 13:00'
            //     },
            //     {
            //         title: '2000000220003',
            //         name: '王小五',
            //         time: '2023/05/27 13:00'
            //     },
            // ]
        }
    },
    mounted() {
        this.initData();
    },
    methods: {
        async initData() {
            try {
                // get user info
                let {code, data} = await request.get('/h5/sampling/user/info');
                if (code === 1) {
                    this.$message.error('获取用户信息失败');
                    return ;
                } 
                this.userInfo.name = data.name;
                this.userInfo.phone = data.mobile;

                ({ code, data } = await request.get('/h5/sampling/user/find/today'));
                if (code === 1) {
                    this.$message.error('获取采样数据失败');
                    return ;
                }
                this.todaySample = data.todaySample;
                this.historyNumber = data.historyNumber;
                this.isSpinning = false;
            } catch (err) {
                console.log(err);
                this.$message.error('获取采样数据失败');
                this.isSpinning = false;
            }

        },
        
        async changeTab(key) {
            this.isSpinning = true;
            
            let { code, data } = await request.get(`/h5/sampling/user/find/${key}`);
            if (code === 1) {
                this.$message.error('获取采样数据失败');
                return ;
            }

            if (key === 'today') {
                this.todaySample = data.todaySample;
            } else if (key === 'history') {
                this.historySample = data.historySample;
            }
            this.historyNumber = data.historyNumber;
            this.isSpinning = false;
        }
    }
}
</script>

<style scoped>
.user-info-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.user-info-avatar {
    width: 25%;
    padding-left: 20px;
}
    
.user-info-detail {
    width: 75%;
    text-align: left;
    padding-left: 10px;
}
.detail-name {
    font-size: 18px;
    font-weight: 800;
    color: #333333;
}

.detail-phone {
    font-size: 13px;
    margin-top: 5px;
    color: #7f7f7f;
}

.record-num {
    font-size: 18px;
    font-weight: 800;
    color: #333333;
}
.record-box {
    text-align: center;
}

.list-box {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    height: calc(100vh - 320px);
    overflow: scroll;
}

.record-detail-info {
    display: flex;
}

.record-detail-info-name {
    width: 50%;
}

.record-detail-info-time {
    width: 50%;
    text-align: right;
}
</style>