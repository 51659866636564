
<template>
    <div class="sample-info">
        <!-- <sample-header header_title="采样信息" /> -->
        <div class="info">
            <div class="info-box">
                <a-card title="样品信息" :bordered="false" :bodyStyle="cardStyle">
                    <div class="record-detail-info">
                        <div class="record-detail-info-title">采样编号：</div>
                        <div class="record-detail-info-num">{{ samplingId }}</div>
                    </div>
                </a-card>
            </div>
            <div class="info-box">
                <a-card title="用户信息" :bordered="false" :bodyStyle="cardStyle">
                    <div class="user-info-list">
                        <div class="user-form-item">
                            <div class="user-detail-type">姓名：</div>
                            <div class="user-detail-val">
                                <a-input placeholder="请输入患者姓名" v-model="patientInfo.name"/>
                            </div>
                        </div>
                        
                        <div class="user-form-item">
                            <div class="user-detail-type">性别：</div>
                            <div class="user-detail-val">
                                <a-radio-group name="radioGroup" :default-value="1" v-model="patientInfo.gender">
                                    <a-radio value="男">
                                        男
                                    </a-radio>
                                    <a-radio value="女">
                                        女
                                    </a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        
                        <div class="user-form-item">
                            <div class="user-detail-type">手机号：</div>
                            <div class="user-detail-val">
                                <a-input placeholder="请输入手机号" v-model="patientInfo.phone"/>
                            </div>
                        </div>
                        
                        <div class="user-form-item">
                            <div class="user-detail-type">身份证号：</div>
                            <div class="user-detail-val">
                                <a-input placeholder="请输入身份证号" @change="changePatientID" v-model="patientInfo.id"/>
                            </div>
                        </div>

                        <div class="user-form-item">
                            <div class="user-detail-type">年龄：</div>
                            <div class="user-detail-val">
                                <a-input v-model="patientInfo.age" :disabled="true"/>
                            </div>
                        </div>
                        <!-- <a-list item-layout="horizontal" :data-source="userData">
                            <a-list-item slot="renderItem" slot-scope="item">
                            <a-list-item-meta>
                                <div class="user-detail" slot="description">
                                    <div class="user-detail-type">{{ item.type }}：</div>
                                    <div class="user-detail-val">
                                        <a-input placeholder="Basic usage" />
                                    </div>
                                </div>
                            </a-list-item-meta>
                            </a-list-item>
                        </a-list> -->
                    </div>
                </a-card>
            </div>
            <div class="info-box">
                <a-card title="采样人" :bordered="false" :bodyStyle="cardStyle">
                    <div class="user-info-list">
                        <a-list item-layout="horizontal" :data-source="samplerInfo">
                            <a-list-item slot="renderItem" slot-scope="item">
                            <a-list-item-meta>
                                <div class="user-detail" slot="description">
                                    <div class="user-detail-type">{{ item.type }}：</div>
                                    <div class="user-detail-val">{{ item.val }}</div>
                                </div>
                            </a-list-item-meta>
                            </a-list-item>
                        </a-list>
                    </div>
                </a-card>
            </div>
        </div>
        <div class="submit-btn" @click="submitSample">立即提交</div>
    </div>
    
</template>


<script>
// import Header from '@/components/header.vue';
import request from '../utils/request.js';
import { debounce } from 'lodash';

const formItemLayout = {
  labelCol: { xs: { span: 4 } },
  wrapperCol: { xs: { span: 10 } },
};

function calculateAge(idNumber) {
  var year = idNumber.substring(6, 10);
  var month = idNumber.substring(10, 12);
  var day = idNumber.substring(12, 14);
  

  var birthDate = new Date(year, parseInt(month) - 1, day);
  var currentDate = new Date();
  var age = currentDate.getFullYear() - birthDate.getFullYear();
  
  if (currentDate.getMonth() < birthDate.getMonth() || 
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
}

export default {
    name: "SampleInfo",
    props: {
        samplingId: {
            type: String,
            default: ''
        }
    },
    // components: {
    //     'sample-header': Header,
    // },
    data() {
        return {
            // samplingId: '2023060500000',
            form: this.$form.createForm(this),
            formItemLayout,
            samplerInfo: [
                {
                    type: '姓名',
                    val: ''
                },
                {
                    type: '手机号',
                    val: ''
                }
            ],
            patientInfo: {
                name: '',
                phone: '',
                age: '',
                gender: 1,
                id: ''
            },
            cardStyle: {
                padding: "10px 24px"
            }
        }
    },
    mounted() {
        this.initSamplerData();
    },
    methods: {
        changePatientID: debounce(function() {
            // 根据身份证号确定年龄
            let { id } = this.patientInfo;
            if (id.length != 18) {
                return ;
            }

            const idRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (idRegex.test(id) == false) {
                this.$message.error('身份证号格式不正确');
                return ;
            }

            let age = calculateAge(id);
            this.patientInfo.age = age;
        }, 300),  

        async initSamplerData() {
            let {code, data} = await request.get('/h5/sampling/user/info');
            if (code === 1) {
                this.$message.error('获取用户信息失败');
                return ;
            } 
            let samplerInfo =  [
                {
                    type: '姓名',
                    val: data.name
                },
                {
                    type: '手机号',
                    val: data.mobile
                }
            ];
            this.samplerInfo = samplerInfo;
        },
        async submitSample() {
            let { name, phone, id, gender } = this.patientInfo;
            if (name.length == 0 || phone.length == 0 || id.length == 0) {
                this.$message.error('请填写完整患者信息');
                return ;
            }
            
            const phoneRegex = /^1[3456789]\d{9}$/;
            if (phoneRegex.test(phone) == false) {
                this.$message.error('手机号格式不正确');
                return ;
            }

            const idRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (idRegex.test(id) == false) {
                this.$message.error('身份证号格式不正确');
                return ;
            }
            
            let payload = {
                samplingId: this.samplingId,
                name: name,
                mobile: phone,
                patientId: id,
                gender: gender
            };
            
            let { code } = await request.post('/h5/sampling/sample/addSample', payload);
            if (code === 1) {
                this.$message.error('提交样本失败');
                return ;
            } 
            this.$message.success('提交样本成功');
            setTimeout(() => {
                window.location.href = '/sample';
            }, 3000);
        }
    }
}
</script>

<style scoped>
.sample-info {
    background: #f7f7f7 !important;
    padding-bottom: 10px;
}

.info {
    width: 90%;
    margin: 0 auto;
}

.info-box {
    margin: 20px 0;
}

.record-detail-info {
    display: flex;
}

.record-detail-info-title {
    width: 50%;
    color: rgba(0, 0, 0, 0.45)
}

.record-detail-info-num {
    width: 50%;
    text-align: right;
}

.user-form-item {
    display: flex;
    align-items: center;
    margin: 10px auto
}

.user-detail {
    display: flex;
}

.user-detail-type {
    width: 30%;
    color: rgba(0, 0, 0, 0.45)
}

.submit-btn {
    width: 80%;
    background: #448ef7;
    color: #ffffff;
    text-align: center;
    font-weight: 800;
    margin: 20px auto;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
}

</style>