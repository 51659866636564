<template>
    <div>
        <sample-header header_title="登录" />
        <div class="avatar-box"><a-avatar :size="100" src="/image/logo.jpg" /></div>
        <div class="login-title">采样小助手</div>
        <div class="form-box">
            <a-form
                ref="form"
                :form="form"
                :wrapper-col="{ span: 24 }"   
                @submit="onSubmit"
            >
                <a-form-item>
                    <a-input class="custom-input" placeholder="请输入手机号码"
                        v-decorator="[
                            'phone',
                            { rules: rules.phone, trigger: 'blur' }
                        ]">
                        <!-- <a-icon slot="prefix" type="mobile" /> -->
                    </a-input>
                </a-form-item>

                <a-form-item
                    ref="password"
                    :rules="[{ required: true, message: 'Please input your password!' }]">
                    <a-input class="custom-input" type="password" placeholder="请输入登录密码"
                        v-decorator="[
                            'password',
                            { rules: rules.password, trigger: 'blur' }
                        ]">
                        <!-- <a-icon slot="prefix" type="lock" /> -->
                    </a-input>
                </a-form-item>

                <a-form-item>
                    <a-button class="login-btn" type="primary" html-type="submit">登录</a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>


<script>
import Header from '@/components/header.vue';
import request from '../utils/request.js';

export default {
    name: "Login",
    components: {
        'sample-header': Header
    },
    data() {
        return {
            form: this.$form.createForm(this),
            rules: {
                phone: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                    {
                        pattern: /^1[3456789]\d{9}$/,
                        message: "手机号码格式不正确",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
            },
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log(values);
                    request.post('/h5/sampling/user/login', values)
                        .then(res => {
                            let { code, data } = res;
                            if (code === 1) {
                                this.$message.error('密码错误');
                            } else {
                                this.$message.success('登录成功，即将跳转');
                                localStorage.setItem('access-token', data.token);
                                window.location.href = '/';
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            });
        }
    }
}
</script>

<style scoped>
.avatar-box {
    margin: 0 auto;
    margin-top: 35px;
    text-align: center;
}
    
.login-title {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    color: #7f7f7f;
}

.form-box {
    width: 90%;
    margin: 40px auto 20px auto;
}

.custom-input {
    border-radius: 22px !important;
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    height: 45px;
    line-height: 45px;
    padding: 0 10px;
    font-size: 12px;
    background: #f9f9f9;
    padding: 0 20px;
}

.login-btn {
    width: 100%;
    border-radius: 10px !important;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    font-weight: 900;
    border: none;
}
</style>